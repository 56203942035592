import deStrings from "./languages/de";
import enStrings from "./languages/en";
import esStrings from "./languages/es";
import frStrings from "./languages/fr";
import itStrings from "./languages/it";
import jaStrings from "./languages/ja";
import koStrings from "./languages/ko";
import ptBrStrings from "./languages/pt-br";
import ruStrings from "./languages/ru";
import zhStrings from "./languages/zh";

const resources = {
  de: deStrings,
  en: enStrings,
  es: esStrings,
  fr: frStrings,
  it: itStrings,
  ja: jaStrings,
  ko: koStrings,
  "pt-rBR": ptBrStrings, // Android
  pt: ptBrStrings, // iOS
  ru: ruStrings,
  zh: zhStrings, // Android
  "zh-Hans": zhStrings, // iOS
};

function extractKey(dict, key) {
  let result = dict;
  let segments = key.split(".");

  for (let i = 0; i < segments.length; i++) {
    const segment = segments[i];
    result = result[segment];
  }

  if (typeof result !== "string") {
    return undefined;
  }

  return result;
}

const i18n = {
  lang: window.clientConfig.lang,
  fallbackLang: "en",

  t: function (key, params = {}) {
    let string = undefined;

    if (typeof resources[this.lang] === "object") {
      string = extractKey(resources[this.lang], key);
    }

    if (string === undefined) {
      string = extractKey(resources[this.fallbackLang], key);
    }

    if (string === undefined) {
      string = key;
    }

    Object.keys(params).forEach((pk) => {
      string = string.replace(new RegExp(`{{${pk}}}`, "g"), params[pk]);
    });

    return string;
  },

  changeLanguage: function(newLanguage) {
    this.lang = newLanguage;
  },
}

export default i18n;
