import React from "react";

export default class TestInstanceIndicator extends React.Component {

  render() {
    return <div style={{
      position: "fixed",
      top: 4,
      left: 4,
      zIndex: 100000,
      pointerEvents: "none",
      display: "flex",
      gap: 1,
    }}>
      {window.appConfig.isTestInstance && <div style={{
        width: 6,
        height: 6,
        borderRadius: "100%",
        background: "#e41818",
      }}/>}
      {window.clientConfig.isTester && <div style={{
        width: 6,
        height: 6,
        borderRadius: "100%",
        background: "#ecbd00",
      }}/>}
    </div>;
  }
}
