import promiseRetry from "promise-retry";
import axios from "axios";

export const apiResponseErrorCodes = {
  INTERNAL: 1,
  INVALID_PARAMS: 2,
  PHOTOLAB: 3,
  NOT_AUTHORIZE: 401,
  NOT_FOUND: 404,
  DELETED: 410,
  FILE_FORMAT_INVALID: 415,
};

const defaultRetriesConfig = {
  retries: 5,
  minTimeout: 2000,
  maxTimeout: 5000
};

export class ApiResponseError extends Error {

  constructor(data) {
    super();

    this.name = "ApiResponseError";
    this.code = data.error_code;
    this.message = data.error_message;
    this.response = data;
  }
}

function checkApiResponse(res) {
  if (res.data.error_code) {
    throw new ApiResponseError(res.data);
  } else {
    return res.data;
  }
}

// eslint-disable-next-line no-unused-vars
function hitRequestFail(err, hitId) {
  // hitEvent(hitId);
  throw err;
}

// --

export function photolabSign(data) {
  const params = {data};
  params.rv = 1;
  params.cv = 1;
  params.cn = window.appConfig.project.name;

  function requestFunc(retry) {
    return window.axios.post(window.appConfig.paths.apiSign + "/photolab/sign", params)
      .catch(retry);
  }

  return promiseRetry(requestFunc, defaultRetriesConfig)
    .then(checkApiResponse);
}

export function storeFile(blob, fileName) {
  return axios({
    method: "PUT",
    url: window.appConfig.paths.proxy + "/upload/npp/creatives/" + fileName,
    headers: {
      "Content-Type": "image/jpeg",
    },
    data: blob
  });
}

export function tempImagesUploadFile(blob, type) {
  const formData = new FormData();
  formData.append("image", blob, "image." + type);

  return axios({
    method: "POST",
    url: window.appConfig.paths.tempImagesUploadEndpoint,
    data: formData,
  }).then((res) => {
    if (/^https?:\/\//i.test(res.data)) {
      return res.data.replace(/^http:/i, "https:");
    } else {
      throw new Error("Image upload contains an unexpected response");
    }
  });
}

export function createFile(file, data, params) {
  params = params || {};

  const formData = new FormData();

  if (file instanceof File) {
    formData.append("file", file);
  } else {
    formData.append("image_url", file);
  }

  if (data) {
    formData.append("image_data", JSON.stringify(data || {}));
  }

  Object.keys(params).forEach((pk) => formData.append(pk, params[pk]));


  function requestFunc(retry) {
    return window.axios.post(window.appConfig.paths.apiUpload + "/files/create", formData, {
      headers: {"Content-Type": "multipart/form-data"}
    }).catch(retry);
  }

  return promiseRetry(requestFunc, defaultRetriesConfig)
    .then(checkApiResponse);
}

export function storeTaskResultImage(file, data, params) {
  params = params || {};

  const formData = new FormData();

  if (file instanceof File) {
    formData.append("file", file);
  } else {
    formData.append("image_url", file);
  }

  if (data) {
    formData.append("image_data", JSON.stringify(data || {}));
  }

  Object.keys(params).forEach((pk) => formData.append(pk, params[pk]));

  function requestFunc(retry) {
    return window.axios.post(window.appConfig.paths.apiUpload + "/files/store_task_tesult_image", formData, {
      headers: {"Content-Type": "multipart/form-data"}
    }).catch(retry);
  }

  return promiseRetry(requestFunc, defaultRetriesConfig)
    .then(checkApiResponse);
}

export function imageEncodeAsDataUrl(url) {
  function requestFunc(retry) {
    return window.axios.get(window.appConfig.paths.api + "/image/encode/data-url?content_url=" + encodeURIComponent(url))
        .catch(retry);
  }

  return promiseRetry(requestFunc, defaultRetriesConfig)
      .then(checkApiResponse);
}

export function createTask(type, params) {
  function requestFunc(retry) {
    return window.axios.post(window.appConfig.paths.api + "/tasks/create", {type, params}).catch(retry);
  }

  return promiseRetry(requestFunc, defaultRetriesConfig)
    .then(checkApiResponse);
}

export function fetchTask(taskId) {
  function requestFunc(retry) {
    return window.axios.get(window.appConfig.paths.api + "/tasks/" + taskId).catch(retry);
  }

  return promiseRetry(requestFunc, defaultRetriesConfig)
    .then(checkApiResponse);
}

export function fetchTasks(tasksIds) {
  function requestFunc(retry) {
    return window.axios.get(window.appConfig.paths.api + "/tasks/batch", {
      params: {
        ids: tasksIds,
      }
    }).catch(retry);
  }

  return promiseRetry(requestFunc, defaultRetriesConfig)
    .then(checkApiResponse);
}

export function processingCommit(data) {
  return window.axios.post(window.appConfig.paths.api + "/log/results", {data})
    .then(checkApiResponse)
}

export function getBuildInfo() {
  return window.axios.get("/build.json")
    .then(checkApiResponse);
}
