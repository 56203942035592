import axios from "axios";

export const signals = {
  photoSelectClick: "photo_select_click",
  photoSelected: "photo_selected",
  processingStarted: "processing_started",
  processingProcessed: "processing_processed",
  processingFailed: "processing_failed",
  processingFailedPhotolab: "processing_failed_photolab",
  download: "download",
  jsGlobalError: "js_global_error",
};

export function signalEvent(signal, amount = 1, ignoreUserGroup = false) {
  if (window.appConfig.isDebug) {
    console.debug("signalEvent", signal, amount);
  }

  if (!window.appConfig.signals.isEnabled) {
    return;
  }

  if (!ignoreUserGroup && !window.appConfig.signals.allowedUserGroups.includes(window.clientConfig.splitGroupId)) {
    return;
  }

  axios.post(window.appConfig.signals.endpoint, {
    project_name: window.appConfig.project.name,
    signal,
    amount,
  }).then(() => {
    /* skip */
  }).catch((err) => {
    console.error(err);
  });
}