function defaultHandler(res) {
  if (res.ok) {
    return res.json();
  } else {
    const error = new Error("ResponseError");
    error.name = "ResponseError";
    error.response = res.clone();

    throw error;
  }
}

function networkErrorHandler(err) {
  const error = new Error("Network error");
  error.name = "NetworkError";
  error.parentError = err;

  throw error;
}

function defaultErrorHandler(err) {
  throw err;
}

function fetch() {
  return window.fetch(window.appConfig.paths.geolocation, {
    method: "GET",
    headers: {
      "Accept": "application/json",
    },
  })
    .catch(networkErrorHandler)
    .then(defaultHandler)
    .catch(defaultErrorHandler);
}

export default {
  fetch: fetch,
};
