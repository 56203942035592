export default class PhotolabTaskCollageMethod {

  constructor(params = {}) {
    this._name = "collage";
    this._params = {...params};
  }

  get name() {
    return this._name;
  }

  get params() {
    return this._params;
  }
}