import React, {Component} from 'react';
import AppContext from "../contexts/AppContext";
import SvgCloseIcon from '../ui/svg/CloseIcon';
import ModalContent from './ModalContent/ModalContent';

export default class Modal extends Component {

  constructor(props) {
    super(props);

    this.overlayRef = React.createRef();
    this.modalContentRef = React.createRef();

    this.dismiss = this.dismiss.bind(this);
    this.renderModal = this.renderModal.bind(this);
    this.handleOverlayClick = this.handleOverlayClick.bind(this);
    this.handleCloseButtonClick = this.handleCloseButtonClick.bind(this);
  }

  dismiss(trigger) {
    if (this.props.onDismiss) {
      if (this.props.onDismiss(this.state, trigger) !== false) {
        this.context.popModal(() => {
          this.props.onDismissed && this.props.onDismissed(this.state, trigger);
        });
      }
    } else {
      this.context.popModal(() => {
        this.props.onDismissed && this.props.onDismissed(this.state, trigger);
      });
    }
  }

  handleOverlayClick(e) {
    if (e.target === this.overlayRef.current || e.target === this.modalContentRef.current) {
      if (this.props.closeByTapOnOverlay) {
        this.dismiss("overlay");
      }
    }
  }

  handleCloseButtonClick(e) {
    this.dismiss();
  }

  renderModal() {
    return <React.Fragment />;
  }

  render() {
    return <div
      ref={this.overlayRef}
      className={`popup-overlay ${this.props.className || ""} ${this.className || ""}`}
      onClick={this.handleOverlayClick}>

      <button
        hidden={this.props.hideCloseButton}
        className="button-close-popup"
        onClick={this.handleCloseButtonClick}>
        <SvgCloseIcon />
      </button>

      <div className="popup">
        <ModalContent children={this.renderModal()} modalRef={this.modalContentRef} />
      </div>

    </div>;
  }
}

Modal.contextType = AppContext;
