import styled from "styled-components";

export const ContainerStyled = styled.div`
  display: ${props => props.flex ? "flex" : "block"};
  flex-direction: ${props => props.direction ? "column" : "row"};
  max-width: 1110px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 15px;
  margin: 0 auto;
`;