import React from "react";
import {ModalContainer, ModalBlock } from "./ModalContent.style";

export default class ModalContent extends React.Component {
  render() {
    return (
      <ModalContainer ref={this.props.modalRef}>
        <ModalBlock>
          {this.props.children}
        </ModalBlock>
      </ModalContainer>
    )
  }
}
