import React from "react";

export default function SvgProcessingIcon() {
  return <svg viewBox="0 0 120 120">
    <g>
      <circle cx="60" cy="60" r="60" fill="#212121"/>
      <path d="M28.242 37.833a8.722 8.722 0 0 0-5.083 13.004 3.816 3.816 0 0 0-2.08 3.524M91.344 56.626a5.709 5.709 0 0 1 4.837 7.99c1.594.3 3.33 1.63 3.41 3.252" stroke="#01E777" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M16.454 92.855a3.682 3.682 0 0 1-1.031-6.917 5.665 5.665 0 0 1 5.057-9.868 5.348 5.348 0 0 1-1.542-5.656 5.092 5.092 0 0 1 4.793-3.101c.776 0 1.55.238 2.326.264a2.53 2.53 0 0 0 2.115-.793c.705-.88.247-2.29-.546-3.128-.793-.837-1.877-1.339-2.723-2.123a5.507 5.507 0 0 1 3.34-9.524 7.674 7.674 0 0 1-5.137-7.049 7.533 7.533 0 0 1 4.969-7.048 6.485 6.485 0 0 1 1.471-6.6 6.344 6.344 0 0 1 6.661-1.26 3.312 3.312 0 0 1 .097-3.673 3.18 3.18 0 0 1 3.454-.925c3.127-7.56 11.19-12.644 19.33-12.644a21.278 21.278 0 0 1 19.163 12.758 5.612 5.612 0 0 1 6.062.881 5.7 5.7 0 0 1 1.586 5.974 2.775 2.775 0 1 1 .951 4.88c3.877.988 7.049 4.68 8.08 8.547a11.568 11.568 0 0 1-3.049 11.013 4.617 4.617 0 0 1 3.7 3.93 4.502 4.502 0 0 1-2.713 4.581 5.621 5.621 0 0 1 5.383 4.52 5.225 5.225 0 0 1-3.718 5.639c-.755.119-1.5.293-2.229.52a1.762 1.762 0 0 0-1.216 1.762c.141.881 1.26 1.33 2.168 1.216.907-.115 1.762-.582 2.643-.696a4.246 4.246 0 0 1 4.334 3.004 6.022 6.022 0 0 1-1.18 5.374 10.573 10.573 0 0 1-4.687 3.181l-77.912 2.96z" fill="#01E777"/>
      <path d="M112.225 99.736c-4.758-9.463-14.238-16.388-24.6-18.573-5.391-1.137-10.572-2.044-15.973-3.18a14.749 14.749 0 0 1-11.304 4.713 15.25 15.25 0 0 1-10.97-5.86L35.45 80.063c-10.854 2.52-21.207 9.691-24.581 20.317L29.37 124h64.828l18.027-24.264z" fill="#686868"/>
      <path d="M38.48 80.009a24.423 24.423 0 0 0 21.41 13.973 24.415 24.415 0 0 0 21.762-13.4M40.436 83.093l3.234-1.48M44.4 87.286l2.776-2.229M49.881 91.057l1.86-2.81M55.916 93.057l1.11-2.943M62.568 89.647l.881 4.053M68.436 88.282l1.762 2.943M73.846 84.775l2.255 2.256M77.361 81.277l2.529 1.234M27.987 82.344l3.78 30.273M91.687 82.802 86.94 113.25" stroke="#fff" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M83.23 99.744a8.687 8.687 0 0 0-11.27 9.243 9.774 9.774 0 0 0 7.868-1.974 9.573 9.573 0 0 0 3.401-7.269z" fill="#fff"/>
      <path d="m70.198 110.476 7.7-7.542" stroke="#fff" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M76.366 80.3c-.97-.3-1.92-.67-2.89-.978.08-1.657.15-3.278.229-4.934a15.126 15.126 0 0 0 7.621-10.573 12.062 12.062 0 0 0 6.494-10.097 6.943 6.943 0 0 0-1.163-4.405 3.86 3.86 0 0 0-4.124-1.498 106.791 106.791 0 0 0-2.352-12.335 12.925 12.925 0 0 1-9.745-3.101c-5.154.564-10.713 1.392-15.859 1.965a3.127 3.127 0 0 1-1.26 5.594c-.581.115-1.277.106-1.647.564-.511.652 0 1.56.352 2.317a4.246 4.246 0 0 1-.616 4.291c-.978 1.172-2.723 2.643-3.965 1.762a7.726 7.726 0 0 0-8.247-.273 8.466 8.466 0 0 0-3.991 7.313 9.366 9.366 0 0 0 4.035 7.383 10.485 10.485 0 0 0 8.344 1.472c0 4.67-.168 9.198-.203 13.859-1.004.29-2.185.599-3.18.88a20.6 20.6 0 0 0 16.15 9.27c6.475.176 12.167-3.278 16.017-8.476z" fill="#212121"/>
      <path d="M75.397 60.916a.66.66 0 1 0 0-1.321.66.66 0 0 0 0 1.321zM78.974 55.63a.66.66 0 1 0 0-1.322.66.66 0 0 0 0 1.322zM74.736 56.564a.66.66 0 1 0 0-1.322.66.66 0 0 0 0 1.322zM78.313 58.74a.66.66 0 1 0 0-1.322.66.66 0 0 0 0 1.322zM71.626 58.423a.66.66 0 1 0 0-1.322.66.66 0 0 0 0 1.322zM57.634 57.392a.66.66 0 1 0 0-1.322.66.66 0 0 0 0 1.322zM53.511 56.176a.66.66 0 1 0 0-1.321.66.66 0 0 0 0 1.321zM61.361 57.392a.66.66 0 1 0 0-1.322.66.66 0 0 0 0 1.322zM57.634 60.916a.66.66 0 1 0 0-1.321.66.66 0 0 0 0 1.321zM54.172 59.084a.66.66 0 1 0 0-1.322.66.66 0 0 0 0 1.322z" fill="#7D7D7D"/>
      <path d="M82.692 47.348a81.588 81.588 0 0 1-1.207 17.269M82.842 53.912l2.484-1.718M40.154 52.573 45.6 56.3M47.185 62.432l.308 3.577M60.445 75.304a15.762 15.762 0 0 0 13.683-.881M54.692 52.176a3.586 3.586 0 0 1 4.854.546M73.07 52.67a3.85 3.85 0 0 1 5.287-.406M66.674 51.7l1.225 7.86-2.767-.115M53.864 45.507a4.24 4.24 0 0 1 6.81-.282M72.533 44.996a4.292 4.292 0 0 1 6.802.22M60.145 60.097a3.48 3.48 0 0 0 6.22 2.202" stroke="#fff" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M69.317 30.432a9.075 9.075 0 0 0 7.242 7.19" stroke="#01E777" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M39.185 65.7a2.432 2.432 0 1 0 0-4.863 2.432 2.432 0 0 0 0 4.863zM84.56 65.7a2.432 2.432 0 1 0 0-4.863 2.432 2.432 0 0 0 0 4.863z" fill="#fff"/>
    </g>
  </svg>;
}