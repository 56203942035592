import React, {Suspense} from "react";
import ReactDOM from "react-dom/client";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import routes from "./routes";
import ModalWrapper from "./components/ModalWrapper";
import {AppContextProvider} from "./contexts/AppContext";
import AppContext from "./contexts/AppContext";
import {logEvent, userEvents} from "./utils/log";
import * as webviewUtils from "./utils/webview";
import ProcessingLoader from "./components/ProcessingLoader/ProcessingLoader";
import ErrorBoundary from "./components/ErrorBoundary";
import {debounce} from "./utils/etc";
import {preloadNativeAds, resetNativeAdsCounter} from "./utils/native-ads";
import {onLCP} from "web-vitals";
import {gtag} from "./utils/ga";
import TestInstanceToast from "./components/TestInstanceToast";
import WindowSizeWatcher from "./components/WindowSizeWatcher";
import { createGlobalStyle } from 'styled-components'
import GlobalStyles from './globalStyles'
import geolocationHelper from "./helpers/geolocation.helper";
import {isProfitUser} from "./utils/config.utils";
import TestInstanceIndicator from "./components/TestInstanceIndicator";

const GlobalStyle = createGlobalStyle`
  :root {
    --window-inner-height: var(--window-inner-height);
  }
`;

gtag("event", "user_data", {
  custom_split_group: "" + window.clientConfig.splitGroupId,
});

if (window.clientConfig.isWebMobile) {
  onLCP((metric) => {
    logEvent(userEvents.LCP_REPORT, {
      value: metric.value,
      delta: metric.delta,
      rating: metric.rating,
    });
    gtag("event", "lcp_report", {
      value: Math.round(metric.delta),
      non_interaction: true,
      transport: "beacon",
    });
  });
}

const LazyIndexPage = window.clientConfig.isWebview
  ? React.lazy(() => import("./pages/landing-webview/IndexPage"))
  : React.lazy(() => import("./pages/landing-desktop/IndexPage"));
const LazyResultPage = React.lazy(() => import("./pages/result/ResultPage"));
const LazyMaskEditorPage = React.lazy(() => import("./pages/MaskEditorPage/MaskEditorPage"));
const LazyProcessingPage = React.lazy(() => import("./pages/ProcessingPage"));
const LazyUploadPage = React.lazy(() => import("./pages/UploadPage"));
const LazyErrorPage = React.lazy(() => import("./pages/ErrorPage"));

class App extends React.Component {
  state = {
    isReady: window.clientConfig.isWebview,
  };

  constructor(props) {
    super(props);

    if (window.clientConfig.isWeb) {
      geolocationHelper.fetch()
        .then((res) => {
          window.clientConfig.geolocation = res;
          window.clientConfig.isCheapUser = !isProfitUser({
            country: res.country,
            geoip_country: res.country,
            localtz: Intl.DateTimeFormat().resolvedOptions().timeZone,
            ...window.clientConfig.queryParams,
          });
          
          this.setState({isReady: true});
        })
        .catch((error) => {
          this.setState({isReady: true});
        });
    }
  }

  componentDidMount() {
    window.nppLoadFont(window.clientConfig.lang);

    resetNativeAdsCounter();

    window.webviewEventsListeners.tabSelected.subscribe((v) => {
      v && this.handleWebviewTabSelected();
    }, true);

    if (window.clientConfig.isWebview) {
      webviewUtils.webviewOnPageFinished();

      setTimeout(() => {
        webviewUtils.webviewCheckInstalledApps((apps) => {
          this.context.setInstalledApps(apps);
        });
      }, 200);
    }

    window.clientConfig.platform.os.toLowerCase() === "ios"
      && document.body.classList.add("ios");

    window.clientConfig.isWebview
      && document.body.classList.add("webview");

    document.body.classList.add(`app-lang--${window.clientConfig.lang}`);

    document.body.classList.add("ui--creative-tabs-v2");

    window.clientConfig.features.showNeuroAvatars && document.body.classList.add("ui--neuroavatars-promo");

    document.body.addEventListener("mousewheel", (e) => {
      // отключаем скейлинг страницы по ctrl+scroll
      if (e.ctrlKey) {
        e.preventDefault();
      }
    }, {passive: false});
  }

  handleWebviewTabSelected = () => {
    debounce("App.handleWebviewTabSelected.preloadAds", 300, () => {
      preloadNativeAds();
    });
  };

  render() {
    return (
      <div>
        <WindowSizeWatcher />
        <GlobalStyles />
        <GlobalStyle />
        <TestInstanceToast />
        <ErrorBoundary>
          <ProcessingLoader {...this.context.loader} />
          <BrowserRouter>
            <Suspense fallback={<div />}>
              {this.state.isReady && <React.Fragment>
                <Switch>
                  <Route exact path={routes.INDEX} render={(props) => <LazyIndexPage {...props} />} />
                  <Route exact path={routes.UPLOAD} render={(props) => <LazyUploadPage {...props} />} />
                  <Route exact path={routes.MASK_EDITOR} render={(props) => <LazyMaskEditorPage {...props} />} />
                  <Route exact path={routes.PROCESSING} render={(props) => <LazyProcessingPage {...props} />} />
                  <Route exact path={routes.RESULT} render={(props) => <LazyResultPage {...props} />} />
                  <Route exact path={routes.ERROR} render={(props) => <LazyErrorPage {...props} />} />
                </Switch>
              </React.Fragment>}
            </Suspense>
          </BrowserRouter>
          <ModalWrapper />
          <TestInstanceIndicator />
        </ErrorBoundary>
      </div>
    );
  }
}

App.contextType = AppContext;

const reactRoot = ReactDOM.createRoot(document.getElementById("root"));
reactRoot.render(<AppContextProvider><App /></AppContextProvider>);