import styled from "styled-components";

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(30px);
  background-color: rgba(1, 231, 119, 0.15);
  z-index: 80;
`;

export const ModalBlock = styled.div`
  max-width: 640px;
  width: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 24px;
  box-shadow: 0 8px 60px 0 rgba(0, 0, 0, 0.6);
  background-color: #000;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 76px 50px;

  p {
    font-size: 24px;
    line-height: 1.5;
    text-align: center;
    color: #fff;
    margin-bottom: 64px;

    span {
      font-weight: bold;
      color: #01e777;
    }
  }

  button {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.2;
    letter-spacing: 2px;
    text-transform: uppercase;
    cursor: pointer;
    background: none;

    &:hover {
      opacity: 0.8;
    }
  }

  .btn-upload-foto {
    color: #fff;
    max-width: 100%;
    width: 100%;
  }

  .rate-button {
    color: #000;
    width: 188px;
    height: 64px;
    border-radius: 40px;
    background-color: #01e777;
    margin-bottom: 20px;
  }

  .creative-download {
    font-weight: 700;
    font-size: 20px;
    text-transform: uppercase;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 188px;
    height: 64px;
    background-color: #01e777;
    border-radius: 56px;

    @media all and (max-width: 780px) {
      font-size: 16px;
      width: 240px;
      height: 48px;
    }
  }

  .skip-button {
    color: #01e777;
    padding: 4px;
  }

  h3 {
    font-size: 40px;
    font-weight: bold;
    line-height: 1.2;
    text-align: center;
    color: #fff;
    margin-bottom: 32px;

    span {
      color: #01e777;
    }
  }

  @media all and (max-width: 780px) {
    padding: 24px;

    h3 {
      font-size: 24px;
      line-height: 1.5;
      margin-bottom: 16px;
    }

    p {
      font-size: 16px;
      margin-bottom: 40px;
    }

    button {
      font-size: 16px;
      letter-spacing: 1.6px;
    }

    .rate-button {
      max-width: 240px;
      width: 100%;
      height: 48px;
      border-radius: 24px;
    }
  }
`;
