module.exports = {

    "try_another_photo": "Cambiar foto",

    "time_next_try": "⏰ Vuelve a intentarlo en {{time}}",

    // "safe_and_cool": "¡Ayúdanos a luchar contra las noticias falsas!",

    "landing__title_1": "Tu maravilloso creador de fotos de perfil.",
    "landing__title_2": "Novedades cada semana. Totalmente basado en IA.",
    "landing__subtitle": "¡El mundo de alrededor está siempre cambiando y tu imagen en línea también debería hacerlo!",
    "landing__get_the_app_button": "Llévate la aplicación",

    "choose_photo": "Elige la foto",

    // "choose_hide_text": "Ocultar subtítulo",
    // "choose_show_text": "Mostrar subtítulo",

    "modal__title": "¡Excelente elección!",
    "modal__subtitle": "Ha llegado el momento de actualizar tu perfil y hacer que tus amigos y seguidores se pongan celosos ;)",

    // "btn_creative_refresh": "+{{num}} más",

    "main_info_title": "A más de 100 000 personas le ha gustado / Más de 100 000 Me gusta",
    "main_info_text": "Asombra a tus amigos y seguidores con avatares renovados que reflejen tu ánimo actual.",

    "rules_list_title": "Las reglas son sencillas:",
    "rules_list_item1": "Selecciona la foto de perfil que más te guste con tu estado de ánimo actual",
    "rules_list_item2": "y vuelve una semana más tarde a por nuevos efectos, nuevas opciones y una foto nueva.",

    "processing_tip": "Consejo {{item}}/{{total}}",
    "processing_text_1": "¿Por qué conformarse con una sola foto de perfil? ¡Deja que tu foto cambie contigo! 🙈",
    "processing_text_2": "¡Rompe las reglas y actualiza la foto tantas veces como quieras!",
    "processing_text_3": "¡Elige la obra de arte que refleje tu estado de ánimo actual! 🤩",
    "processing_text_4": "Sorprende a tus amigos con un nuevo y bonito perfil todo el tiempo 👍",
    "processing_text_5": "Vuelve en un par de días para obtener nuevas ideas 😉",

    // "processing2_0": "¡Rompe las reglas! Hazlo a tu manera:",
    // "processing2_1": "Actualiza tu foto de perfil una vez cada dos días 🔄",
    // "processing2_2": "Observa un aumento en la atención a tu perfil a medida que lo haces 😎",
    // "processing2_3": "Vuelve para nuevos efectos y diseños 🤩",
    // "processing2_4": "Repite 🚀",

    "resubscribe_text1": "&nbsp;",
    "resubscribe_text2": "Vaya, tenemos un problema...",
    "resubscribe_text3": "Ha interrumpido su suscripción y ahora todas las funciones Premium están bloqueadas.<br />Vuelva a suscribirse para solucionarlo.",

    "maskeditor__title": "Refinar la selección",
    "maskeditor__subtitle": "Desplázate para hacer zoom, arrastra con el botón central del ratón",

    "btn_undo": "Deshacer",
    "btn_redo": "Rehacer",
    "btn_save": "Guardar",
    "size": "Tamaño",
    "btn_get": "CONSIGUE LA APLICACIÓN GRATUITA",

    "result__creative_download": "Descargar",
    "result__creative_download_webview": "Guardar y compartir",
    "result__creative_retry": "Reintentar",
    "result__creative_failed_message": "Vaya, algo ha ido mal con este estilo. 😔 Elige cualquier otro estilo o prueba de nuevo.",
    "result__creative_saved_to_gallery": "Guardado en la galería",
    // "result__choose_title": "¿No puedes elegir?",
    // "result__choose_text": "Pide ayuda a tus seguidores publicando un collage con diversas opciones",

    "rateapp_dialog__title": "¿Te gusta <span>#NewProfilePic</span>?",
    "rateapp_dialog__message": "¡Dale estrellas a la aplicación y ayuda a otros usuarios a descubrirla y disfrutarla también!",
    "rateapp_dialog__rate_button": "¡Puntúanos!",
    "rateapp_dialog__skip_button": "Saltar",

    // "ad_tab_title": "Encuentra aún más cosas increíbles en nuestra aplicación <span>Photo Lab</span>",

    "error__error": "Error",
    "modal_button": "Conseguido",

    "remove_logo_modal__title": "Mantener el logo o no mantenerlo…",
    "remove_logo_modal__message": "Hola, ¿sabías que el logo es una parte esencial del diseño de este efecto? Nos gustaría que lo mantuvieras.",
    "remove_logo_modal__btn_cancel": "Mantener el logo",
    "remove_logo_modal__btn_remove": "Eliminarlo de todas maneras",

    "toggle_watermark_tooltip__text": "Tap en el logo para removerlo",
    "toggle_watermark_on": "Con logo",
    "toggle_watermark_off": "Sin logo",

    "internal_error": "Ha ocurrido un error...",
    "error__network_message": "Por favor, comprueba tu conexión de red.",

    "error_overload": "Lo lamentamos enormemente, estamos teniendo altas cargas en el servidor debido a las elevadas descargas de la aplicación. Por favor, vuelve un poco más tarde. Estamos haciendo un gran esfuerzo para solucionarlo todo en breve.",

    // "timer__title": "Nuevos estilos en:",
    // "timer__expired_text_landing": "Elige una foto para probar nuevos estilos",
    // "timer__expired_text_result": "Elige una foto para probar nuevos estilos:",

    // "timer__days_one": "día",
    // "timer__days_other": "días",
    // "timer__hours_one": "hora",
    // "timer__hours_other": "horas",
    // "timer__minutes_one": "minuto",
    // "timer__minutes_other": "minutos",
    // "timer__seconds_one": "segundo",
    // "timer__seconds_other": "segundos",

    "contact_us": "Contáctanos",

    // "btn_full_size_results_v1": "¡Novedad! <span>Resultados a tamaño completo</span>",
    "btn_full_size_results_v2": "¡Novedad! Resultados a tamaño completo",
};

