import React from "react";
import i18n from "../../i18n";
import FileChooseButton from "../FileChooseButton";
import {ApiResponseError, apiResponseErrorCodes} from "../../utils/api";
import {assetUrl} from "../../utils/etc";
import { Content, Image, Parent, ChooseBtn, ContentText } from "./ErrorView.style";
import Container from "../Container/Container";
import {signalEvent, signals} from "../../utils/signals";

export default class ErrorView extends React.Component {

  state = {
    timeStart: 0,
    timeLeft: 0,
    timeLeftProgress: 0,
    message: "",
  };

  componentDidMount() {
    this.setState({timeStart: Date.now()});

    const isProcessingTimeoutError = this.props.error && this.props.error.type === "processing_timeout";
    const isPhotolabError = this.props.error && this.props.error.type === "photolab";
    const isPhotolabSystemError = isPhotolabError && this.props.error.code > -1000;

    let message = i18n.t("error__default_message");
    let buttonWithTimer = false;

    if (isPhotolabSystemError || isProcessingTimeoutError) {
      message = i18n.t("error_overload");
      buttonWithTimer = true;
    } else if (isPhotolabError) {
      message = this.props.error.message;
    } else if (this.props.error instanceof ApiResponseError
      && this.props.error.code === apiResponseErrorCodes.FILE_FORMAT_INVALID)
    {
      message = i18n.t("error__api_code_415");
    }

    this.setState({message});

    if (buttonWithTimer) {
      this.timer = setInterval(() => {
        const timeoutMs = window.appConfig.processings.errorButtonTimeout;
        const timeLeft = Math.max(0, (this.state.timeStart + timeoutMs) - Date.now());
        const timeLeftProgress = (timeoutMs - timeLeft) / timeoutMs;
        this.setState({timeLeft, timeLeftProgress});
      }, 1000/60);
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  formatMillisToTime = (millis) => {
    const seconds = ("0" + Math.floor(millis / 1000)).slice(-2);
    const minutes = ("0" + Math.floor(seconds / 60)).slice(-2);

    return `${minutes}:${seconds}`;
  };

  handleFileChooseButtonClick = () => {
    signalEvent(signals.photoSelectClick);
  };

  render() {
    const isPhotolabError = this.props.error && this.props.error.type === "photolab";

    let buttonText = isPhotolabError ? i18n.t("try_another_photo") : i18n.t("choose_photo");
    if (this.state.timeLeft > 0) {
      buttonText = i18n.t("time_next_try", {
        time: this.formatMillisToTime(this.state.timeLeft),
      });
    }

    return <Parent>
      <Container flex direction>
        <Content>
          <Image src={assetUrl("assets/images/rocketman.svg")} alt="." />
          {/* <ContentTitle>{i18n.t("error__error")}</ContentTitle> */}
          <ContentText>{this.state.message}</ContentText>
        </Content>
        <ChooseBtn>
          <FileChooseButton
            disabled={this.state.timeLeft > 0}
            onClick={this.handleFileChooseButtonClick}
            onFileSelected={this.props.onFileSelected}
            children={buttonText}
            progress={this.state.timeLeftProgress}
            progressContent={buttonText} />
        </ChooseBtn>
      </Container>
    </Parent>;
  }
}
