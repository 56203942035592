import profitCountriesList from "../data/countries-profit.json";
import profitTimezonesList from "../data/timezones-profit.json";
import {envAsBoolean} from "../config";

export function isTokenSymbol(index, symbols = "") {
  const [,, token] = (window.clientConfig.token).toLowerCase().split(":");
  symbols = symbols.toLowerCase();

  if (index < 0) {
    index = token.length - Math.abs(index);
  }

  return symbols.indexOf(token[index]) >= 0;
}

export function isWebviewBuildIos(version = 0) {
  return window.clientConfig.isWebviewIOS
    && window.clientConfig.queryParams.version_code >= version;
}

export function isWebviewBuildAndroid(version = 0) {
  return window.clientConfig.isWebviewAndroid
    && window.clientConfig.queryParams.version_code >= version;
}

export function isWebviewBuild(android, ios) {
  if (window.clientConfig.isWebviewIOS) {
    return window.clientConfig.queryParams.version_code >= ios;
  } else if (window.clientConfig.isWebviewAndroid) {
    return window.clientConfig.queryParams.version_code >= android;
  } else {
    return false;
  }
}

export function isWebviewCountryOf(code, onlyGeo = false) {
  code = code.toLowerCase();

  if ((window.clientConfig.queryParams.geoip_country || "").toLowerCase() === code) {
    return true;
  }

  return onlyGeo
    ? false
    : ((window.clientConfig.queryParams.country || "").toLowerCase() === code);
}

export function isWebviewCountriesOf(codes, onlyGeo = false) {
  codes = codes.map((code) => code.toLowerCase());
  const geoipCode = (window.clientConfig.queryParams.geoip_country || "").toLowerCase();

  if (codes.includes(geoipCode)) {
    return true;
  }

  const countryCode = (window.clientConfig.queryParams.country || "").toLowerCase();

  return onlyGeo
    ? false
    : codes.includes(countryCode);
}

export function isWebCountriesOf(codes) {
  codes = codes.map((code) => code.toLowerCase());
  const geoipCode = (window.clientConfig.geolocation.country || "").toLowerCase();

  return codes.includes(geoipCode);
}

export function whenSplit() {
  const items = (arguments.length > 0 && Array.isArray(arguments[0]))
    ? arguments[0]
    : arguments;

  return [].indexOf.call(items, window.clientConfig.splitGroupId) > -1;
}

export function whenSplitInRange(start, end) {
  return window.clientConfig.splitGroupId >= start
    && window.clientConfig.splitGroupId <= end;
}

export const publishAtByTime = ({pos = -1, date, minutes = 0, range = 16}) => {
  const shift = Math.floor((Date.now() - Date.parse(date)) / (minutes * 60_000));
  return isTokenSymbol(pos, "0123456789abcdef".substring(0, Math.min(range, shift)));
}

export function isProfitUser(query) {
  if (envAsBoolean(process.env.REACT_APP_DEV_FORCE_PROFIT_USER)) {
    return true;
  }

  if (typeof query["cc"] === "string") {
    return query["cc"] !== "1";
  }

  return query["ishk"] !== "1"
    && profitCountriesList.includes((query["country"] || "").toUpperCase())
    && profitCountriesList.includes((query["geoip_country"] || "").toUpperCase())
    && profitTimezonesList.includes(query["localtz"]);
}