module.exports = {

    "try_another_photo": "Cambia foto",

    "time_next_try": "⏰ Riprova tra {{time}}",

    // "safe_and_cool": "Aiutaci a combattere le fake news!",

    "landing__title_1": "Il tuo fantastico generatore di foto profilo.",
    "landing__title_2": "Nuovi arrivi settimanali. Basato totalmente sull'intelligenza artificiale.",
    "landing__subtitle": "Il mondo cambia in continuazione e anche la tua immagine online dovrebbe!",
    "landing__get_the_app_button": "Scarica l'app",

    "choose_photo": "Scegli la foto",

    // "choose_hide_text": "Nascondi didascalia",
    // "choose_show_text": "Mostra didascalia",

    "modal__title": "Ottima scelta!",
    "modal__subtitle": "Ora è il momento di aggiornare la foto del tuo profilo e far ingelosire i tuoi amici e follower ;)",

    // "btn_creative_refresh": "+{{num}}",

    "main_info_title": "Piace a oltre 100.000 persone/100.000+ like",
    "main_info_text": "Stupisci i tuoi amici e follower con avatar sempre aggiornati, che riflettono il tuo umore del momento.",

    "rules_list_title": "Le regole sono semplici:",
    "rules_list_item1": "Seleziona l'immagine del profilo che più ti piace nel tuo stato d'animo attuale",
    "rules_list_item2": "e torna una settimana dopo per nuovi effetti, nuove opzioni e una nuova immagine.",

    "processing_tip": "Suggerimento {{item}}/{{total}}",
    "processing_text_1": "Perché limitarsi a una foto del profilo? Lascia che cambi con te! 🙈",
    "processing_text_2": "Infrangi le regole e aggiorna le foto tutte le volte che vuoi!",
    "processing_text_3": "Scegli la grafica che rispecchia il tuo umore attuale! 🤩",
    "processing_text_4": "Sorprendi ogni volta i tuoi amici con un nuovo bellissimo profilo 👍",
    "processing_text_5": "Torna tra un paio di giorni per nuove idee 😉",

    // "processing2_0": "Infrangi le regole! Fallo a modo tuo:",
    // "processing2_1": "Aggiorna la tua foto profilo una volta ogni due giorni 🔄",
    // "processing2_2": "Nel farlo, vedrai aumentare l'attenzione sul tuo profilo 😎",
    // "processing2_3": "Ritorna per nuovi effetti e design 🤩",
    // "processing2_4": "Ripeti 🚀",

    "resubscribe_text1": "&nbsp;",
    "resubscribe_text2": "Ops, abbiamo un problema...",
    "resubscribe_text3": "Hai interrotto l'abbonamento e ora tutte le funzionalità Premium sono bloccate.<br />Iscriviti nuovamente per risolvere il problema.",

    "maskeditor__title": "Rifinire la selezione",
    "maskeditor__subtitle": "Scorri per ingrandire, trascina con il tasto centrale del mouse",

    "btn_undo": "Annulla",
    "btn_redo": "Rifare",
    "btn_save": "Salvare",
    "size": "Dimensioni",
    "btn_get": "SCARICA L'APP GRATIS",

    "result__creative_download": "Scaricare",
    "result__creative_download_webview": "Salva e Condividi",
    "result__creative_retry": "Riprova",
    "result__creative_failed_message": "Ops, qualcosa è andato storto con questo stile. 😔 Scegli un altro stile o riprova.",
    // "result__choose_title": "Non riesci a scegliere?",
    // "result__choose_text": "Chiedi ai tuoi follower di aiutarti pubblicando un collage con diverse opzioni",

    "rateapp_dialog__title": "Ti piace <span>#NewProfilePic</span>?",
    "rateapp_dialog__message": "Assegna delle stelle all'app e aiuta anche altri utenti a scoprirci e apprezzarci!",
    "rateapp_dialog__rate_button": "Valuta!",
    "rateapp_dialog__skip_button": "Salta",

    // "ad_tab_title": "Scopri cose ancor più fantastiche sulla nostra app <span>Photo Lab</span>",

    "error__error": "Errore",
    "modal_button": "Capito",

    "remove_logo_modal__title": "Mantenerlo o non mantenerlo…",
    "remove_logo_modal__message": "Ehi, lo sapevi che il logo fa parte del design di questo effetto? Scegli di mantenerlo, ne saremo felici.",
    "remove_logo_modal__btn_cancel": "Mantieni il logo",
    "remove_logo_modal__btn_remove": "Rimuovilo comunque",

    "toggle_watermark_tooltip__text": "Toccare il logo per rimuoverlo",
    "toggle_watermark_on": "Logo sì",
    "toggle_watermark_off": "Logo no",

    "internal_error": "Si è verificato un errore...",
    "error__network_message": "Controlla la connessione di rete.",

    "error_overload": "Siamo terribilmente spiacenti, ma stiamo riscontrando un enorme sovraccarico sui server, a causa degli elevati download dell'app. Ti preghiamo di ritornare un po' più tardi: stiamo lavorando sodo per risolvere tutto in breve tempo.",

    // "timer__title": "Nuovi stili tra:",
    // "timer__expired_text_landing": "Scegli una foto per provare nuovi stili",
    // "timer__expired_text_result": "Scegli una foto per provare nuovi stili:",
    // "timer__expired_button": "",

    // "timer__days_one": "giorno",
    // "timer__days_other": "giorni",
    // "timer__hours_one": "ora",
    // "timer__hours_other": "ore",
    // "timer__minutes_one": "minuto",
    // "timer__minutes_other": "minuti",
    // "timer__seconds_one": "secondo",
    // "timer__seconds_other": "secondi",

    "contact_us": "Contattaci",

    // "btn_full_size_results_v1": "Novità! <span>Risultati a grandezza naturale</span>",
    "btn_full_size_results_v2": "Novità! Risultati a grandezza naturale",

};

