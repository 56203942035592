import React from "react";
import Modal from "./Modal";
import i18n from "../i18n";
import AppContext from "../contexts/AppContext";
import {hitEvent, hits, logEvent, userEvents} from "../utils/log";
import {APPSTORE_LINK, PLAY_MARKET_LINK} from "../utils/constants";

export default class ContinueWithAppModal extends Modal {

  handleContinueButtonClick = (e) => {
    hitEvent(hits.GET_APP_CLICK);
    logEvent(userEvents.GET_APP_CLICK, {page: "continue_with_app"});

    setTimeout(() => {
      window.location.href = window.clientConfig.platform.os.toLowerCase() === "ios"
        ? APPSTORE_LINK
        : PLAY_MARKET_LINK;
    }, 300);
  };

  handleDismissButtonClick = (e) => {
    this.dismiss();
  };

  renderModal() {
    return <>
      <h3 dangerouslySetInnerHTML={{__html: i18n.t("continue_with_app__title")}} />
      <p dangerouslySetInnerHTML={{__html: i18n.t("continue_with_app__message")}}></p>
      <button
        className="rate-button"
        children={i18n.t("continue_with_app__continue_button")}
        onClick={this.handleContinueButtonClick} />
      <button
        className="skip-button"
        children={i18n.t("continue_with_app__dismiss_button")}
        onClick={this.handleDismissButtonClick} />
    </>
  }
}

ContinueWithAppModal.contextType = AppContext;
