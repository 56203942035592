module.exports = {

    "try_another_photo": "写真を変更",

    "time_next_try": "⏰ 再試行: {{time}}",

    // "safe_and_cool": "フェイクニュースとの戦いにお力をお貸しください！",

    "landing__title_1": "驚きのプロフィール写真メーカー",
    "landing__title_2": "毎週新コンテンツが登場。完全にAI主導。",
    "landing__subtitle": "周りの世界は常に変化しており、あなたのオンラインイメージも変化するはず！",
    "landing__get_the_app_button": "アプリを入手",

    "choose_photo": "画像を選ぶ",

    // "choose_hide_text": "キャプションを非表示",
    // "choose_show_text": "キャプションを表示",

    "modal__title": "いいチョイス！",
    "modal__subtitle": "プロフィール写真をアップデートして、仲間やフォロワーをうらやましがらせよう。;)",

    // "btn_creative_refresh": "{{num}}以上",

    "main_info_title": "10万人以上が高評価 / 10万件以上の高評価",
    "main_info_text": "あなたの現在の気分を反映した新鮮なアバターで友達やフォロワーを驚かせましょう。",

    "rules_list_title": "ルールは単純です。",
    "rules_list_item1": "現在の心の状態で最も好きなプロフィール写真を選択してください",
    "rules_list_item2": "1週間後に戻ってきて、新しい効果、新しいオプション、新しい画像を入手します。",

    "processing_tip": "ヒント {{item}}/{{total}}",
    "processing_text_1": "同じプロフィール写真を使い続ける必要なんてありません。自分の変化に合わせて変えましょう！🙈",
    "processing_text_2": "ルールを破って好きなだけ写真を更新！😎",
    "processing_text_3": "今のムードにピッタリのアートを選択！🤩",
    "processing_text_4": "毎回美しくプロフィールを更新して友達を驚かせよう 👍",
    "processing_text_5": "数日後に新しいアイデアがないかまたご覧ください 😉",

    // "processing2_0": "ルールを破れ！自己流に:",
    // "processing2_1": "数日ごとにプロフィール写真を更新 🔄",
    // "processing2_2": "そうすることでプロフィールへの注目度がアップ 😎",
    // "processing2_3": "新しいエフェクトやデザインを追加 🤩",
    // "processing2_4": "その繰り返し 🚀",

    "resubscribe_text1": "&nbsp;",
    "resubscribe_text2": "おや、問題が発生したようです…",
    "resubscribe_text3": "購買停止されたことにより、すべてのPremium機能がロックされています。<br />元に戻すには再購買してください。",

    "maskeditor__title": "選択範囲を絞り込む",
    "maskeditor__subtitle": "スクロールしてズームし、マウスの中央ボタンでドラッグします",

    "btn_undo": "取り消す",
    "btn_redo": "やり直す",
    "btn_save": "保存",
    "size": "サイズ",
    "btn_get": "無料のアプリを入手",

    "result__creative_download": "ダウンロード",
    "result__creative_download_webview": "保存とシェア",
    "result__creative_retry": "再試行",
    "result__creative_failed_message": "このスタイルで問題が発生しました。😔 他のスタイルを選択するか、再試行してください。",
    // "result__choose_title": "選べませんか？",
    // "result__choose_text": "さまざまなオプションでコラージュを投稿して、フォロワーに助けを求めましょう",

    "rateapp_dialog__title": "<span>#NewProfilePic</span>をお気に入りいただけましたか？",
    "rateapp_dialog__message": "アプリを星評価して、他のユーザーにすばらしさを伝えましょう！",
    "rateapp_dialog__rate_button": "評価する！",
    "rateapp_dialog__skip_button": "スキップ",

    // "ad_tab_title": "<span>Photo Lab</span> アプリには他にも素晴らしい機能が満載",

    "error__error": "エラー",
    "modal_button": "了解",

    "remove_logo_modal__title": "そのままにするかしないか…",
    "remove_logo_modal__message": "ロゴがこのエフェクトのデザインの一部だと知ってましたか？そのままにしてもらったらすごくうれしいです。",
    "remove_logo_modal__btn_cancel": "ロゴをそのままにする",
    "remove_logo_modal__btn_remove": "でも削除する",

    "toggle_watermark_tooltip__text": "ロゴをタップして削除",
    "toggle_watermark_on": "ロゴのオン/オフ",
    "toggle_watermark_off": "ロゴのオン/オフ",

    "internal_error": "エラーが発生しました...",
    "error__network_message": "ネットワーク接続を確認してください。",

    "error_overload": "申し訳ありませんが、アプリのダウンロード量が増加しているためサーバーの負荷が増加しています。しばらくしてから再度お試しください。現在問題を修正できるよう対応を行っています。",

    // "timer__title": "新しいスタイルまで:",
    // "timer__expired_text_landing": "写真を選んで新しいスタイルを試そう",
    // "timer__expired_text_result": "写真を選んで新しいスタイルを試そう:",

    // "timer__days": "日",
    // "timer__hours": "時間",
    // "timer__minutes": "分",
    // "timer__seconds": "秒",

    "contact_us": "お問い合わせ",

    // "btn_full_size_results_v1": "新着! <span>フルサイズの編集結果</span>",
    "btn_full_size_results_v2": "新着! フルサイズの編集結果",

};

