import React from "react";
import i18n from "../../i18n";
import { AvatarImage, Container, CryptoBanner, SpinnerBig, SpinnerContainer, SpinnerSmall, SpinnerSvgContainer, Text, TextContainer, TextTip } from "./ProcessingLoader.style";
import SvgProcessingIcon from "../../ui/svg/ProcessingIcon";
import {webviewAnalyticsEvent, webviewOpenBrowser} from "../../utils/webview";
import AppContext from "../../contexts/AppContext";
import clientStorage from "../../utils/client-storage";

const LOADING_TEXT_INTERVAL = 3000;

export default class ProcessingLoader extends React.Component {

  constructor(props) {
    super(props);

    this.texts = [
      "processing_text_1",
      "processing_text_2",
      "processing_text_3",
      "processing_text_4",
      "processing_text_5",
    ];

    this.state = {
      imageIsLoaded: false,
      //textIndex: Math.floor(Math.random() * (this.texts.length - 1)),
      textIndex: 0,
      isShowCryptoBanner: undefined
    };

    this.updateTextIndexTimer = null;

    this.image = new Image();
    this.image.onload = () => this.setState({imageIsLoaded: true});
    this.isClickedBanner = false;
  }

  componentDidMount() {
    if (this.props.image) {
      this.image.src = this.props.image;
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.image !== this.props.image) {
      this.setState({imageIsLoaded: false}, () => {
        if (this.props.image) {
          this.image.src = this.props.image;
        }
      });
    }

    if (prevProps.isHidden !== this.props.isHidden) {
      if (this.props.isHidden) {
        clearInterval(this.updateTextIndexTimer);
        this.updateTextIndex();

        if (this.state.isShowCryptoBanner && !this.isClickedBanner) {
          webviewAnalyticsEvent("ad_banner_close", [
            "crypto",
            "crypto_1",
            "processing",
            Math.ceil(clientStorage.getProcessingBannerCounter() / 2),
            clientStorage.getSelectedPhotosAmount(),
            clientStorage.getProcessedPhotosAmount(),
          ], {
            wt_npp: {
              link: window.clientConfig.features.cryptoBannerLink
            },
          });
        }
      } else {
        this.updateTextIndexTimer = setInterval(this.updateTextIndex, LOADING_TEXT_INTERVAL);

        this.setState({
          isShowCryptoBanner: window.clientConfig.features.withCryptoBanner
            && Boolean(this.context.installedApps.telegram)
            && !window.clientConfig.isPro 
            && clientStorage.getProcessingBannerCounter() % 2 === 0
        }, () => {
          clientStorage.incrementProcessingBannerCounter();
    
          if (this.state.isShowCryptoBanner) {
            webviewAnalyticsEvent("ad_banner_show", [
              "crypto",
              "crypto_1",
              "processing",
              Math.ceil(clientStorage.getProcessingBannerCounter() / 2),
              clientStorage.getSelectedPhotosAmount(),
              clientStorage.getProcessedPhotosAmount(),
            ], {
              wt_npp: {
                link: window.clientConfig.features.cryptoBannerLink
              },
            });
          }
        });
      }
    }
  }

  componentWillUnmount() {
    this.image.onload = () => {};
    clearInterval(this.updateTextIndexTimer);
  }

  updateTextIndex = () => {
    if (this.state.textIndex === this.texts.length - 1) {
      this.setState({textIndex: 0});
    } else {
      this.setState({textIndex: this.state.textIndex + 1});
    }
  }

  handleBannerClick = () => {
    webviewAnalyticsEvent("ad_banner_click", [
      "crypto",
      "crypto_1",
      "processing",
      Math.ceil(clientStorage.getProcessingBannerCounter() / 2),
      clientStorage.getSelectedPhotosAmount(),
      clientStorage.getProcessedPhotosAmount(),
    ], {
      wt_npp: {
        link: window.clientConfig.features.cryptoBannerLink
      },
    });

    this.isClickedBanner = true;

    webviewOpenBrowser(window.clientConfig.features.cryptoBannerLink);
  }

  render() {
    return <Container hidden={this.state.isShowCryptoBanner === undefined || this.props.isHidden}>
      <SpinnerContainer showCryptoBanner={this.state.isShowCryptoBanner}>
        <SpinnerBig showCryptoBanner={this.state.isShowCryptoBanner}>
          <SpinnerSmall showCryptoBanner={this.state.isShowCryptoBanner} />
        </SpinnerBig>

        {!this.state.imageIsLoaded && <SpinnerSvgContainer showCryptoBanner={this.state.isShowCryptoBanner}>
          <SvgProcessingIcon />
        </SpinnerSvgContainer>}

        {this.state.imageIsLoaded && <AvatarImage
          src={this.props.image}
          showCryptoBanner={this.state.isShowCryptoBanner}
          alt="" />}
      </SpinnerContainer>
      <TextContainer hidden={this.props.hideText} showCryptoBanner={this.state.isShowCryptoBanner}>
        <TextTip showCryptoBanner={this.state.isShowCryptoBanner} dangerouslySetInnerHTML={{__html: i18n.t("processing_tip", {item: this.state.textIndex + 1, total: this.texts.length})}} />
        <Text showCryptoBanner={this.state.isShowCryptoBanner} dangerouslySetInnerHTML={{__html: i18n.t(this.texts[this.state.textIndex]) || ""}} />
      </TextContainer>

      <CryptoBanner hidden={!this.state.isShowCryptoBanner} onClick={this.handleBannerClick}>
        <source srcSet={`/assets/images/banner/game_banner.webp`} type="image/webp" />
        <img src={`/assets/images/banner/game_banner.jpg`} alt="" />
      </CryptoBanner>
    </Container>;
  }
}

ProcessingLoader.contextType = AppContext;
