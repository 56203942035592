module.exports = {

    "try_another_photo": "사진 변경",

    "time_next_try": "⏰ 나중에 다시 시도: {{time}}",

    // "safe_and_cool": "가짜 뉴스와 싸울 수 있게 도와주세요!",

    "landing__title_1": "멋진 프로필 사진 메이커",
    "landing__title_2": "매주 제공되는 신규 업데이트 전체 AI 기반",
    "landing__subtitle": "세상이 변하는 만큼 온라인 이미지도 변해야 겠죠!",
    "landing__get_the_app_button": "앱 받기",

    "choose_photo": "사진 선택",

    // "choose_hide_text": "캡션 숨기기",
    // "choose_show_text": "캡션 보기",

    "modal__title": "훌륭한 선택입니다!",
    "modal__subtitle": "이제 프로필 사진을 업데이트하고 친구와 팔로워를 질투하게 만드세요. ;)",

    // "btn_creative_refresh": "{{num}}장 이상",

    "main_info_title": "10만명 이상이 좋아합니다/ 좋아요 10만명 이상",
    "main_info_text": "지금 나의 기분을 반영한 아바타로 친구들과 팔로워를 놀라게 하세요.",

    "rules_list_title": "규칙은 간단합니다:",
    "rules_list_item1": "지금 마음에 드는 프로필 사진을 선택하고",
    "rules_list_item2": "일주일 후에 방문하면 새로운 효과, 옵션, 사진을 만날 수 있습니다.",

    "processing_tip": "팁 {{item}}/{{total}}",
    "processing_text_1": "하나의 프로필 사진만 고수할 이유가 있을까요? 멋지게 변경해보세요! 🙈",
    "processing_text_2": "규칙은 깨고 원하는 만큼 자주 사진을 업데이트하세요! 😎",
    "processing_text_3": "내 기분을 나타내는 작품을 선택하세요! 🤩",
    "processing_text_4": "매번 새로운 아름다운 프로필 사진으로 친구들을 놀라게 해주세요 👍",
    "processing_text_5": "며칠 후에 다시 오면 새로운 아이디어를 더 많이 얻을 수 있습니다. 😉",

    // "processing2_0": "규칙을 깨세요! 나만의 방식으로 하세요:",
    // "processing2_1": "2~3일에 한 번씩 프로필 사진을 업데이트하세요 🔄",
    // "processing2_2": "프로필에 관심이 높아지는 걸 확인하세요 😎",
    // "processing2_3": "돌아와서 새로운 효과와 디자인을 사용하세요 🤩",
    // "processing2_4": "반복 🚀",

    "resubscribe_text1": "&nbsp;",
    "resubscribe_text2": "이런, 문제가 생겼습니다…",
    "resubscribe_text3": "구독을 중단하여 모든 Premium 기능이 잠겼습니다.<br />다시 구독하여 문제를 해결하세요.",

    "maskeditor__title": "선택 수정",
    "maskeditor__subtitle": "확대하려면 마우스 중간 버튼을 굴리세요.",

    "btn_undo": "실행 취소",
    "btn_redo": "재실행하기",
    "btn_save": "저장",
    "size": "크기",
    "btn_get": "무료 앱 받기",

    "result__creative_download": "다운로드",
    "result__creative_download_webview": "저장 & 공유",
    "result__creative_retry": "다시 시도하기",
    "result__creative_failed_message": "아하, 이 스타일에 문제가 있는 것 같습니다. 😔 다른 스타일을 선택하거나 다시 시도하세요.",
    // "result__choose_title": "선택할 수 없나요?",
    // "result__choose_text": "다양한 옵션이 포함된 콜라주를 게시하여 팔로워에게 도움을 요청하세요.",

    "rateapp_dialog__title": "<span>#NewProfilePic</span>가 마음에 드세요?",
    "rateapp_dialog__message": "앱에 별을 주어 다른 사용자도 이 앱을 발견하고 좋아할 수 있게 도와주세요!",
    "rateapp_dialog__rate_button": "평가하기!",
    "rateapp_dialog__skip_button": "건너뛰기",

    // "ad_tab_title": "<span>Photo Lab</span> 앱에서 더 멋진 기능들을 찾아보세요",

    "error__error": "오류",
    "modal_button": "확인",

    "remove_logo_modal__title": "유지할까, 말까...",
    "remove_logo_modal__message": "헤이, 이 로고가 이 효과의 디자인에 포함되어 있다는 것을 알고 있었나요? 이 로고를 유지하면 우리는 행복할 거예요.",
    "remove_logo_modal__btn_cancel": "로고 유지하기",
    "remove_logo_modal__btn_remove": "어쨌든 제거하기",

    "toggle_watermark_tooltip__text": "로고를 누르면 없어집니다",
    "toggle_watermark_on": "로고 온/오프",
    "toggle_watermark_off": "로고 온/오프",

    "internal_error": "오류가 발생했습니다...",
    "error__network_message": "네트워크 연결을 확인하세요.",

    "error_overload": "대단히 죄송합니다. 앱 다운로드 수가 많아 서버 부하가 높습니다. 잠시 후에 다시 확인해주세요. 모든 것을 빠르게 해결하기 위해 노력하고 있습니다.",

    // "timer__title": "새 스타일까지 남은 시간:",
    // "timer__expired_text_landing": "새 스타일을 적용해 볼 사진을 고르세요",
    // "timer__expired_text_result": "새 스타일을 적용해 볼 사진을 고르세요:",

    // "timer__days_one": "하루",
    // "timer__days_other": "일",
    // "timer__hours": "시간",
    // "timer__minutes": "분",
    // "timer__seconds": "초",

    "contact_us": "연락처",

    // "btn_full_size_results_v1": "새 기능! <span>전체 크기 업데이트</span>",
    "btn_full_size_results_v2": "새 기능! 전체 크기 업데이트",

};

