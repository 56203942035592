module.exports = {

    "try_another_photo": "Alterar foto",

    "time_next_try": "⏰ Tente novamente em {{time}}",

    // "safe_and_cool": "Ajude-nos a combater as fake news!",

    "landing__title_1": "Seu incrível criador de fotos de perfil.",
    "landing__title_2": "Novidades toda semana. Totalmente orientado por IA.",
    "landing__subtitle": "O mundo ao redor não para de mudar e sua imagem on-line também deve seguir o ritmo!",
    "landing__get_the_app_button": "Baixe o app",

    "choose_photo": "Escolha uma foto",

    // "choose_hide_text": "Ocultar legenda",
    // "choose_show_text": "Mostrar legenda",

    "modal__title": "Excelente escolha!",
    "modal__subtitle": "Agora é hora de atualizar sua imagem de perfil e deixar amigos e seguidores com inveja ;)",

    // "btn_creative_refresh": "+{{num}} mais",

    "main_info_title": "Mais de 100 mil pessoas curtiram/mais de 100 mil likes",
    "main_info_text": "Surpreenda seus amigos e seguidores com novos avatares que refletem seu humor atual.",

    "rules_list_title": "As regras são simples:",
    "rules_list_item1": "Selecione sua foto de perfil preferida em seu estado de espírito atual",
    "rules_list_item2": "e volte dentro de uma semana para novos efeitos, novas opções e nova imagem.",

    "processing_tip": "Dica {{item}}/{{total}}",
    "processing_text_1": "Por que ter só uma foto de perfil? Deixe ela mudar junto com você! 🙈",
    "processing_text_2": "Quebre as regras e atualize a foto quantas vezes quiser! 😎",
    "processing_text_3": "Escolha a obra de arte que reflita seu humor do momento! 🤩",
    "processing_text_4": "Surpreenda seus amigos com um perfil novo e bonito todas as vezes 👍",
    "processing_text_5": "Volte em alguns dias para ver novas ideias 😉",

    // "processing2_0": "Quebre as regras! Faça do seu jeito:",
    // "processing2_1": "Atualize sua foto de perfil uma vez a cada poucos dias 🔄",
    // "processing2_2": "Veja como seu perfil chama mais atenção conforme você faz isso 😎",
    // "processing2_3": "Volte para conferir novos efeitos e designs 🤩",
    // "processing2_4": "Repita 🚀",

    "resubscribe_text1": "&nbsp;",
    "resubscribe_text2": "Opa, temos um problema...",
    "resubscribe_text3": "Você interrompeu sua assinatura e agora todos os recursos Premium estão bloqueados.<br />Assine novamente para corrigir isso.",

    "maskeditor__title": "Ajustar a seleção",
    "maskeditor__subtitle": "Role para ampliar ou reduzir, arraste com o botão do meio do mouse",

    "btn_undo": "Desfazer",
    "btn_redo": "Refazer",
    "btn_save": "Salvar",
    "size": "Tamanho",
    "btn_get": "BAIXE O APP GRÁTIS",

    "result__creative_download": "Baixar",
    "result__creative_download_webview": "Salvar e compartilhar",
    "result__creative_retry": "Tentar novamente",
    "result__creative_failed_message": "Opa, algo saiu errado com esse estilo. 😔 Escolha qualquer outro estilo ou tente novamente.",
    // "result__choose_title": "Não consegue escolher?",
    // "result__choose_text": "Peça ajuda aos seus seguidores postando uma colagem com diferentes opções",

    "rateapp_dialog__title": "Ama o <span>#NewProfilePic</span>?",
    "rateapp_dialog__message": "Dê estrelas para o app e ajude outros usuários a descobrirem e amarem o app também!",
    "rateapp_dialog__rate_button": "Avalie!",
    "rateapp_dialog__skip_button": "Ignorar",

    // "ad_tab_title": "Encontre ainda mais coisas incríveis no nosso app <span>Photo Lab</span>",

    "error__error": "Erro",
    "modal_button": "Entendi",

    "remove_logo_modal__title": "Manter ou não manter…",
    "remove_logo_modal__message": "Ei, sabia que o logo faz parte do design deste efeito? Vai nos deixar felizes se você decidir mantê-lo.",
    "remove_logo_modal__btn_cancel": "Manter o logo",
    "remove_logo_modal__btn_remove": "Removê-lo assim mesmo",

    "toggle_watermark_tooltip__text": "Clique no logotipo para removê-lo",
    "toggle_watermark_on": "Logo ativado",
    "toggle_watermark_off": "Logo desativado",

    "internal_error": "Ocorreu um erro...",
    "error__network_message": "Por favor, verifique sua conexão de rede.",

    "error_overload": "Desculpe, nosso servidor está sobrecarregado devido ao grande número de downloads do app. Por favor, tente novamente daqui a pouco. Estamos trabalhando muito para consertar tudo em breve.",

    // "timer__title": "Novos estilos em:",
    // "timer__expired_text_landing": "Escolha a foto e teste novos estilos",
    // "timer__expired_text_result": "Escolha a foto e teste novos estilos:",

    // "timer__days_one": "dia",
    // "timer__days_other": "dias",
    // "timer__hours_one": "hora",
    // "timer__hours_other": "horas",
    // "timer__minutes_one": "minuto",
    // "timer__minutes_other": "minutos",
    // "timer__seconds_one": "segundo",
    // "timer__seconds_other": "segundos",

    "contact_us": "Fale conosco",

    // "btn_full_size_results_v1": "Novidade! <span>Resultados em tamanho real</span>",
    "btn_full_size_results_v2": "Novidade! Resultados em tamanho real",

};

