export default class PhotolabTaskBuilder {

  _language = "en";
  _methods = [];
  _images = [];

  setLanguage = (language) => {
    this._language = language;

    return this;
  };

  addMethod = (method, index) => {
    this._methods.push({
      item: method,
      index: index || (this._methods.length + 1),
    });

    return this;
  };

  addImage = (imageUrl, index) => {
    this._images.push({
      item: imageUrl,
      index: index || (this._images.length + 1),
    });

    return this;
  };

  buildToJs = () => {
    const task = {
      owner: this.getOwnerString(),
      lang: this._language,
      images: [],
      methods: [],
      abortMethodsChainOnError: true,
      abortAttemptsOnError: true,
    };

    this._methods.forEach((item) => {
      task.methods.push({
        name: item.item.name,
        params: item.item.params,
      });
    });

    this._images.forEach((item) => {
      const imageParams = {
        url: item.item.url,
      };

      if (typeof item.item.rotation === "number" && item.item.rotation !== 0) {
        imageParams.rotation = item.item.rotation;
      }

      if (typeof item.item.flip === "number" && item.item.flip !== 0) {
        imageParams.flip = item.item.flip;
      }

      if (typeof item.item.altBody === "string" && item.item.altBody.length > 0) {
        imageParams.alt_body = item.item.altBody;
      }

      if (Array.isArray(item.item.rect) && item.item.rect.length === 4) {
        const isRelative = item.item.rect.every((p) => p <= 1);
        imageParams[isRelative ? "rectf" : "rect"] = item.item.rect;
      }

      task.images.push(imageParams);
    });

    return task;
  };

  build = () => {
    const doc = document.implementation.createDocument("", "", null);
    const rootElement = doc.createElement("image_process_call");

    const langElement = doc.createElement("lang");
    langElement.innerHTML = this._language;
    rootElement.appendChild(langElement);

    const methodsListElement = doc.createElement("methods_list");

    this._methods.forEach((item) => {
      const methodElement = doc.createElement("method");
      methodElement.setAttribute("order", item.index);

      const nameElement = doc.createElement("name");
      nameElement.innerHTML = item.item.name;

      const paramsElement = doc.createElement("params");
      paramsElement.innerHTML = Object.keys(item.item.params).map((k) => `${k}=${item.item.params[k]}`).join(";");

      methodElement.appendChild(nameElement);
      methodElement.appendChild(paramsElement);

      methodsListElement.appendChild(methodElement);
    });

    rootElement.appendChild(methodsListElement);

    this._images.forEach((item) => {
      const imageUrlElement = doc.createElement("image_url");
      imageUrlElement.setAttribute("order", item.index);

      if (typeof item.item.rect === "string" && item.item.rect.length > 0) {
        imageUrlElement.setAttribute("rect", item.item.rect);
      }

      if (Array.isArray(item.item.rect) && item.item.rect.length === 4) {
        const isRelative = item.item.rect.every((p) => p <= 1);
        imageUrlElement.setAttribute(
          isRelative ? "rectf" : "rect",
          "(" + item.item.rect.join(",") + ")"
        );
      }

      if (typeof item.item.rotation === "number" && item.item.rotation !== 0) {
        imageUrlElement.setAttribute("rotation", item.item.rotation);
      }

      if (typeof item.item.flip === "number" && item.item.flip !== 0) {
        imageUrlElement.setAttribute("flip", item.item.flip);
      }

      if (typeof item.item.altBody === "string" && item.item.altBody.length > 0) {
        imageUrlElement.setAttribute("alt_body", item.item.altBody);
      }

      imageUrlElement.innerHTML = item.item.url;

      rootElement.appendChild(imageUrlElement);
    });

    const abortMethodsChainOnErrorElement = doc.createElement("abort_methods_chain_on_error");
    abortMethodsChainOnErrorElement.innerHTML = "1";
    rootElement.appendChild(abortMethodsChainOnErrorElement);

    const abortAttemptsOnErrorElement = doc.createElement("abort_attempts_on_error");
    abortAttemptsOnErrorElement.innerHTML = "1";
    rootElement.appendChild(abortAttemptsOnErrorElement);

    const ownerElement = doc.createElement("owner");
    ownerElement.innerHTML = this.getOwnerString();
    rootElement.appendChild(ownerElement);

    doc.appendChild(rootElement);

    return new XMLSerializer().serializeToString(doc);
  };

  getOwnerString = () => {
    return [
      "web",
      window.appConfig.project.name,
      window.appConfig.isDebug ? "debug" : window.appConfig.build.version,
    ].join("-");
  }
}
