function WebviewEventListener(initialValue) {
  let value = initialValue;
  const subscribers = [];

  this.subscribe = (listener, emitNow) => {
    emitNow = emitNow || false;

    const subscriber = new WebviewEventSubscriber(this, listener);
    subscribers.push(subscriber);

    if (emitNow) {
      subscriber.dispatch(value);
    }

    return subscriber;
  };

  this.unsubscribe = (subscriber) => {
    const pos = subscribers.indexOf(subscriber);
    if (pos > -1) {
      subscribers.splice(pos, 1);
    }
  };

  this.postValue = (newValue) => {
    value = newValue;
    for (let i = 0; i < subscribers.length; i++) {
      subscribers[i].dispatch(value);
    }
  };
}

function WebviewEventSubscriber(holder, listener) {
  this.dispatch = (value) => listener(value);
  this.unsubscribe = () => holder.unsubscribe(this);
}

function BackPressListener() {
  const stack = [];
  this.push = (cb) => stack.push(cb);
  this.pop = () => stack.pop();
  this.dispatch = () => {
    if (stack.length === 0) {
      return false;
    }

    return stack[stack.length - 1]() || false;
  }
}

function WebviewDataHolder() {
  let data = null;
  let listener = null;
  this.setData = (newData) => {
    if (listener) {
      listener(newData);
      data = null;
    } else {
      data = newData;
    }
  }
  this.setListener = (cb) => {
    listener = cb;
    if (data != null) {
      listener(data);
      data = null;
    }
  }
  this.removeListener = () => listener = null;
}

// --

window.webviewEventsListeners = {
  tabSelected: new WebviewEventListener(0),
  tabDeselected: new WebviewEventListener(0),
  deeplinkParams: new WebviewEventListener(0),
  setPrice: new WebviewEventListener(null),
  backPress: new BackPressListener(),
  photoSelected: new WebviewDataHolder(),
};

// ---

window.webview_tab_selected = function() {
  window.webviewEventsListeners.tabSelected.postValue(Date.now());
};

window.webview_tab_deselected = function() {
  window.webviewEventsListeners.tabDeselected.postValue(Date.now());
};

window.webviewDeeplinkParams = function(data) {
  window.webviewEventsListeners.deeplinkParams.postValue(data);
};

window.set_price = function(data) {
  window.webviewEventsListeners.setPrice.postValue(data);
}

window.onShowView = function() {

};

window.onNativeAppPhotoSelected = function(data) {
  window.webviewEventsListeners.photoSelected.setData(data);
};

window.onBackPressed = () => {
  return window.webviewEventsListeners.backPress.dispatch();
};