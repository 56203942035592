import React from "react";

export default class TestInstanceToast extends React.Component {

  state = {
    hidden: true,
  }

  componentDidMount() {
    this.show();

    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "visible") {
        this.show();
      }
    });
  }

  show = () => {
    if (window.clientConfig.isWeb) {
      return;
    }

    if (!window.appConfig.isTestInstance) {
      return;
    }

    this.setState({hidden: false}, () => {
      // setTimeout(() => this.setState({hidden: true}), 5000);
    });
  };

  render() {
    if (this.state.hidden) {
      return <React.Fragment />;
    }

    return <div
      className="app-toast-top"
      onClick={() => this.setState({hidden: true})}>
      {/*{new URL(window.location.href).hostname}*/}
      geoip: {window.clientConfig.queryParams.geoip_country}
      is_non_profit: {JSON.stringify(window.clientConfig.isCheapUser)}
    </div>;
  }
}