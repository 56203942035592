import styled from "styled-components";

export const Parent = styled.main`
  display: flex;
  align-items: center;
  height: 94vh;
`;

export const ContentTitle = styled.h2`
  font-weight: 700;
  margin-bottom: 16px;
`;

export const Image = styled.img`
  height: 200px;
  margin-bottom: 48px;

  @media all and (max-width: 780px) {
    position: absolute;
    top: 0;
    left: -30px;
  }

  @media all and (max-height: 540px) and (orientation: portrait) {
    height: 160px;
    margin-bottom: 24px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 1.38;
  margin-bottom: 24px;

  @media all and (max-width: 780px) {
    position: relative;
    padding-top: 248px;
  }

  @media all and (max-height: 540px) and (orientation: portrait) {
    padding-top: 208px;
  }
`;

export const ContentText = styled.p`
  text-align: center;
  line-height: 1.5;
`;

export const ChooseBtn = styled.div`
  button {
    font-size: 24px;
    font-weight: 700;
    color: #01e777;
    text-transform: uppercase;
    display: block;
    flex-shrink: 0;
    width: auto;
    max-width: inherit;
    height: 80px;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    border: 1px solid #01e777;
    border-radius: 100px;
    position: relative;
    overflow: hidden;
    padding: 0 34px;
    margin: 48px auto 0;
  }

  .effects {
    position: absolute;
    left: 0;
    top: 0;
    width: 50%;
    height: 100%;
    background-color: #01e777;
    color: #000000;
    border-radius: 100px;
    overflow: hidden;
    white-space: nowrap;
    display: flex;
    align-items: center;

    span {
      padding-left: 34px;
    }
  }

  @media all and (max-width: 780px) {
    button {
      height: 40px;
      letter-spacing: 1.3px;
      font-size: 13px;
      padding: 0 12px;
    }

    .effects {
      span {
        padding-left: 12px;
      }
    }
  }

  @media all and (max-height: 540px) and (orientation: portrait) {
    button {
      margin-top: 16px;
    }
  }
`;
