module.exports = {

    "try_another_photo": "Foto ändern",

    "time_next_try": "⏰ Versuchen Sie es in {{time}} erneut",

    // "safe_and_cool": "Kämpfen Sie mit uns gegen Fake News!",

    "landing__title_1": "Ihr erstaunlicher Profilbildersteller.",
    "landing__title_2": "Wöchentlich frische Neuheiten. Völlig KI-gesteuert.",
    "landing__subtitle": "Die Welt um Sie herum verändert sich ständig, und Ihr Online-Image sollte das auch tun!",
    "landing__get_the_app_button": "Holen Sie sich die App",

    "choose_photo": "Wählen Sie ein Foto",

    // "choose_hide_text": "Bildunterschrift ausblenden",
    // "choose_show_text": "Bildunterschrift anzeigen",

    "modal__title": "Gute Wahl!",
    "modal__subtitle": "Jetzt ist es an der Zeit, dein Profilbild zu aktualisieren und deine Freunde und Follower neidisch zu machen ;)",

    "btn_creative_refresh": "+{{num}} mehr",

    "main_info_title": "100К+ Leuten hat es gefallen / 100K+ Likes",
    "main_info_text": "Verblüffen Sie Ihre Freunde und Follower mit frisch aussehenden Avataren, die Ihre aktuelle Stimmung widerspiegeln.",

    "rules_list_title": "Die Regeln sind einfach:",
    "rules_list_item1": "Wählen Sie das Profilbild aus, das Ihnen in Ihrem aktuellen Gemütszustand am besten gefällt",
    "rules_list_item2": "und kehren Sie eine Woche später für neue Effekte, neue Optionen und ein neues Bild zurück.",

    "processing_tip": "Tipp {{item}}/{{total}}",
    "processing_text_1": "Wozu auf ein Profilbild beschränken? Verändern Sie es mit Ihnen! 🙈",
    "processing_text_2": "Brich die Regeln und aktualisiere das Foto so oft du möchtest!",
    "processing_text_3": "Wähle die Bildgestaltung, die deine aktuelle Stimmung widerspiegelt! 🤩",
    "processing_text_4": "Überrasche deine Freunde jedes Mal mit einem neuen schönen Profil 👍",
    "processing_text_5": "Kommen Sie in ein paar Tagen wieder, um neue Ideen zu erhalten 😉",

    // "processing2_0": "Brich die Regeln! Mach es auf deine Art:",
    // "processing2_1": "Aktualisiere dein Profilbild alle paar Tage 🔄",
    // "processing2_2": "Indem du das tust, bekommt dein Profil mehr Aufmerksamkeit 😎",
    // "processing2_3": "Komme für neue Effekte und Designs zurück 🤩",
    // "processing2_4": "Wiederholen 🚀",

    "resubscribe_text1": "&nbsp;",
    "resubscribe_text2": "Hoppla, ein Problem ist aufgetreten ...",
    "resubscribe_text3": "Sie haben Ihr Abonnement gekündigt und nun sind alle Premium-Funktionen gesperrt.<br />Melden Sie sich erneut an, um das Problem zu beheben.",

    "maskeditor__title": "Auswahl verfeinern",
    "maskeditor__subtitle": "Zum Zoomen scrollen, mit der mittleren Maustaste ziehen",

    "btn_undo": "Widerrufen",
    "btn_redo": "Wiederholen",
    "btn_save": "Speichern",
    "size": "Größe",
    "btn_get": "KOSTENLOSE APP BESORGEN",

    "result__creative_download": "Herunterladen",
    "result__creative_download_webview": "Speichern und teilen",
    "result__creative_retry": "Erneut versuchen",
    "result__creative_failed_message": "Ups, bei diesem Stil ist etwas schief gelaufen. 😔 Wählen Sie einen anderen Stil oder versuchen Sie es erneut.",
    "result__creative_saved_to_gallery": "In der Galerie gespeichert",
    // "result__choose_title": "Sie können sich nicht entscheiden?",
    // "result__choose_text": "Bitten Sie Ihre Follower um Hilfe, indem Sie eine Collage mit verschiedenen Optionen posten",

    "rateapp_dialog__title": "Liebst du <span>#NewProfilePic</span>?",
    "rateapp_dialog__message": "Gib der App Sterne und hilf anderen Nutzern, sie auch zu entdecken und zu lieben!",
    "rateapp_dialog__rate_button": "Bewerten!",
    "rateapp_dialog__skip_button": "Überspringen",

    // "ad_tab_title": "In unserer <span>Photo Lab</span>-App findest du noch mehr tolle Dinge",

    "error__error": "Fehlermeldung",
    "modal_button": "Verstanden",

    "remove_logo_modal__title": "Behalten oder nicht behalten...",
    "remove_logo_modal__message": "Hey, wusstest du, dass das Logo ein Teil des Effektdesigns ist? Wir würden uns freuen, wenn du dich dafür entscheidest, es beizubehalten.",
    "remove_logo_modal__btn_cancel": "Das Logo behalten",
    "remove_logo_modal__btn_remove": "Trotzdem entfernen",

    "toggle_watermark_tooltip__text": "Tippen Sie auf das Logo, um es zu entfernen",
    "toggle_watermark_on": "Logo ein",
    "toggle_watermark_off": "Logo aus",

    "internal_error": "Ein Fehler ist aufgetreten ...",
    "error__network_message": "Bitte überprüfen Sie Ihre Netzwerkverbindung.",

    "error_overload": "Es tut uns furchtbar leid, aber aufgrund von erhöhten App-Downloads sind unsere Server stark belastet. Bitte schaue etwas später wieder vorbei. Wir arbeiten intensiv daran, alles in Kürze zu beheben.",

    // "timer__title": "Neue Stile in:",
    // "timer__expired_text_landing": "Foto wählen, neue Stile probieren",
    // "timer__expired_text_result": "Foto wählen, neue Stile probieren:",

    // "timer__days_one": "Tag",
    // "timer__days_other": "Tage",
    // "timer__hours_one": "Stunde",
    // "timer__hours_other": "Stunden",
    // "timer__minutes_one": "Minute",
    // "timer__minutes_other": "Minuten",
    // "timer__seconds_one": "Sekunde",
    // "timer__seconds_other": "Sekunden",

    "contact_us": "Kontaktieren Sie uns",

    // "btn_full_size_results_v1": "Neu! <span>Ergebnisse in voller Größe</span>",
    "btn_full_size_results_v2": "Neu! Ergebnisse in voller Größe",

};

